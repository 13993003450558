import configurations from "../configurations/configurations"

export const getScreenSaverInfo = async(householdId)=>{

    const url = configurations.baseUrl + configurations.urlEnv + `getdefaultscreensaver/public?household_id=${householdId}`

    try{
        const response = await fetch(url,{
            method:"GET",
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return await response.json()
    }catch(err){
        console.log(err,"getScreenSaverInfo api failed")
    }
}