import { Lightning, Utils } from '@lightningjs/sdk'

export default class LogoutBtn extends Lightning.Component {
    static _template(){
        return{
            Logout:{
                zIndex:11,
                w:179,
                h:60,
                texture:lng.Tools.getRoundRect(179, 60, 5, 1, 0xff37A0EA,true,0xffF3F6F9),
                flex:{direction:"row",justifyContent:"center",alignItems:"center"},
                BtnText:{
                    text:{
                        text:"Log Out",
                        fontWeight:500,
                        fontSize:22,
                        textColor:"0xff37A0EA",
                        lineHeight:27.72,
                        verticalAlign:"bottom",
                        fontFace:"Medium"
                    },
                    flexItem:{marginRight:8}
                },  
            }
        }
    }

    set label(data){
        this.tag("BtnText").patch({
            text:{
                text:data
            }
        })
    }

    
    _focus(){
        this.tag("Logout").patch({
            texture:lng.Tools.getRoundRect(179, 60, 5, 1, 0xff37A0EA,true,0xff37A0EA),
            BtnText:{
                text:{
                    textColor:"0xffffffff",
                }
            }
        })
    }

    _unfocus(){
        this.tag("Logout").patch({
            texture:lng.Tools.getRoundRect(179, 60, 5, 1, 0xff37A0EA,true,0xffF3F6F9),
            BtnText:{
                text:{
                    textColor:"0xff37A0EA",
                }
            }
        })
    }


}