import { Router, Storage } from "@lightningjs/sdk"
import configurations from "../configurations/configurations"

export const setAsScreenSaver = async(screensaverId,screensaverUrl)=>{

    const url = configurations.baseUrl + configurations.urlEnv + `setscreensaver?screensaver_id=${screensaverId}&screensaver_url=${screensaverUrl}`

    const idToken = Storage.get("idToken")
    
    try{
        const response = await fetch(url,{
            method:"GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
        })

        if(response.status === 200){
            return await response.json()
        }else{
            Storage.remove("idToken")
            Router.navigate("login")
        }
    }catch(err){
        console.log(err,"setAsScreenSaver api failed")
    }
}