import { Lightning, Router } from '@lightningjs/sdk'
import Carousel from './Carousel'
import { List } from '@lightningjs/ui'
import LogoutBtn from './LogoutBtn'
import StreamBtn from './StreamBtn'

export default class ListBoxNormalStream extends Lightning.Component {
    static _template(){
        return{
            texture:lng.Tools.getRoundRect(1760, 393.65, 8, 1, 0xffCCCCCC, true, 0xffFFFFFF),
            h:393.65,
            FlexBox:{
                y:16,
                x:19,
                flex:{direction:"column"},
                CarouselName:{
                    text:{
                        text:"Cityscape",
                        fontWeight:400,
                        fontSize:24,
                        textColor:0xff141414,
                        fontFace:"Medium"
                    }
                },
                TotalPhotos:{
                    text:{
                        text:"0 images",
                        fontWeight:400,
                        fontSize:22,
                        textColor:0xff999999,
                        fontFace:"Medium"
                    }
                },
            },

            CardList:{
                x: 4,
                y:100,
                type:Carousel,
            },
            

            BtnList:{
                x:1226,
                y:317.65,
                type:List,
            }


        }
    }

    _active(){
        this.setToCarouselState()
    }

    
    set itemData(value){
        this.streamData = value
        this.tag("CarouselName").patch({
            text:{
                text:value.stream_name
            }
        })
        this.tag("TotalPhotos").patch({
            text:{
                text: `${value?.images?.length || 0} images`
            }
        })
        
        
        if(value?.screensaver_url){
            this.tag("BtnList").clear()
            this.tag("BtnList").patch({
                x:1226,
            })
            this.tag("BtnList").items = [
              { x: 0, w: 256, label: "Preview", btnWidth: 256, type:StreamBtn  },
              { x: 16, w: 246, label: "Set as screensaver", btnWidth: 246, type:StreamBtn },
            ];
        }else{
            this.tag("BtnList").clear()
            this.tag("BtnList").patch({
                x:1400,
            })
            this.tag("BtnList").items = [
                { x: 60, w: 246, label: "Processing ...", btnWidth: 246, type:StreamBtn },
              ];
        }
        
        this.tag("CardList").carouselData = value
    }

    btnListEnterHandler(selectedIndexValue){
        let value = this.tag("BtnList").items[selectedIndexValue]?.btnLabel
        switch(value){
            case "Preview":{
                Router.getActivePage().startPreviewShow(this.streamData?.stream_id || "")
                break;
            }
            case "Set as screensaver":{
                //need to pass some id so, that carousel is set to selected stream
                Router.getActivePage().setScreenSaverApiLogicFunc(this.streamData)
                break;
            }
            case "Processing ...":{
                console.log("screensaver under process....")
                break;
            }
            default:{
                break;
            }
        }
    }

    setToCarouselState(){
        this._setState('CardList');
    }

    setToBtnListState(){
        this._setState('BtnList');
    }


    static _states() {
        return[
            class CardList extends this{
                $enter(){
                    
                }
                $exit(){
                    
                }
                _getFocused(){
                    return this.tag("CardList")
                }
                _handleDown(){
                    this.setToBtnListState()
                }
            },
            class BtnList extends this{
                $enter(){
                    
                }
                $exit(){
                    
                }
                _getFocused(){
                    return this.tag("BtnList")
                }
                _handleUp(){
                    this.setToCarouselState()
                }
                _handleEnter(){
                    const currentIndex = this.tag("BtnList")._index
                    this.btnListEnterHandler(currentIndex)
                }
            }
        ]
    }

}