import { Lightning, Router, Storage } from '@lightningjs/sdk'
import {  TopStrip } from '../components'
import ImgPreview from '../components/mainpage/ImgPreview'
import LogoutPopup from '../components/mainpage/LogoutPopup'
import VerticalList from '../components/mainpage/VerticalList'

import { fetchAllStreams } from '../services/fetchAllStreams'
import { setAsScreenSaver } from '../services/SetScreenSaver'
import { getSelectedStream } from '../services/GetSelectedStream'

export default class Main extends Lightning.Component {
    static _template() {
        return {
            color:0xffF3F6F9,
            w:1920,
            h:1080,
            rect:true,
            TopStrip:{
                type:TopStrip
            },
            ImgPreview:{
                type:ImgPreview
            },
            LogoutPopup:{
                type:LogoutPopup
            },
            VerticalList:{   
                type:VerticalList
            },
        }
    }

    _inactive(){
        this.streamInterval && clearInterval(this.streamInterval)
    }

    streamChecker(){
        this.streamInterval = setInterval(async()=>{
            const response = await fetchAllStreams()

            //if screensaver url is available,update ui
            const isUrlPending = this.checkForScreensaverUrl(response)
            if(!isUrlPending){
                this.streamData = response
                this.widgets.loader.alpha = 1

                //dont need to call api again
                clearInterval(this.streamInterval)
                
                const response2 = await getSelectedStream()
                this.selectedStreamId = (response2 && response2.screen_saver_id) || ""

                //update ui
                let dataObj = {
                    streamData:this.streamData,
                    selected:this.selectedStreamId
                }
                if(this.streamData.length > 0){
                    this.tag("VerticalList").setCarouselData(dataObj)
                    this.setCarouselState()
                }else{
                    // show empty 
                    this.setTopStripState()
                }

                this.widgets.loader.alpha = 0
            }
            
        },30000)
    }

    async getStreamData(){
        this.widgets.loader.alpha = 1
        const response = await fetchAllStreams()
        const response2 = await getSelectedStream()

        
        // temporarily allStreamRes instead of api response
        this.streamData = response 
        this.selectedStreamId = (response2 && response2.screen_saver_id) || ""
        this.isReloadApi = response.length ? this.checkForScreensaverUrl(response) : false
        if(this.isReloadApi){
            setTimeout(()=>{
                this.streamChecker()
            },30000)
        }
    }

    //returns true if screensaver_url is empty  or key is unavailable
    checkForScreensaverUrl(value){
        let flag = false;
        value.forEach(obj => {
            if (!obj.hasOwnProperty('screensaver_url') || obj.screensaver_url === '') {
                flag = true;
            }
        });
        return flag;
    }


    AddSrcToImgPreview(images,index){
        this.imgPreviewData = images
        this.cardIndex = index
        this.tag("ImgPreview").setImgSrc(images[index],images.length,index)
        this.setImagePreviewState()
    }

    changeImgPreviewImg(url,total,currentIndex,keyPressed){
        this.tag("ImgPreview").setImgSrc(url,total,currentIndex,keyPressed)
    }

    _active(){
       this.fireAncestors("$hideLogoBg")
       this.setCarouselState()
        let dataObj = {
            streamData:this.streamData,
            selected:this.selectedStreamId
        }

        if(this.streamData.length > 0){
            this.tag("VerticalList").setCarouselData(dataObj)
        }else{
            // show empty 
            this.setTopStripState()
        }
    
        this.widgets.loader.alpha = 0

        this.tag("LogoutPopup").alpha = 0.0001
        this.tag("ImgPreview").alpha = 0.0001
    }

    startPreviewShow(id){
        const currentItem = this.streamData.find((val)=>val.stream_id === id)

        //replace playUrl with screensaver_url
        Router.navigate("slideshow",{playUrl:currentItem.screensaver_url})

    }

    _getFocused(){
        return this.tag("VerticalList")
    }

    _handleBack(){
        this.application.closeApp() //app exit instead of logout
        window.close()
    }

    logoutUser(){
        Storage.remove("idToken")
        Storage.set("isFreshAtv",1)
        Storage.set("isFromHomePage",true)
        Router.navigate("login",false)
    }


    async setScreenSaverApiLogicFunc(streamData){
        this.widgets.loader.alpha = 1
        await setAsScreenSaver(streamData.stream_id,streamData.screensaver_url)
        await this.getStreamData()
        
        this.setScreensaverState()

        let dataObj = {
            streamData:this.streamData,
            selected:this.selectedStreamId
        }
        if(this.streamData.length > 0){
            this.tag("VerticalList").setCarouselData(dataObj)
            this.setCarouselState()
        }else{
            // show empty 
            this.setTopStripState()
        }
        this.widgets.loader.alpha = 0
    }

    setTopStripState(){
        this._setState('TopStrip');
    }

    setCarouselState(){
        this._setState('VerticalList');
    }
    

    setScreensaverState(){
        this._setState('Screensaver');
    }

    setImagePreviewState(){
        this._setState('ImgPreview');
    }

    setLogoutPopupState(){
        this._setState('LogoutPopup');
    }
    

    static _states() {
        return[
            class TopStrip extends this{
                _getFocused(){
                    return this.tag("TopStrip")
                }
                _handleDown(){
                    this.streamData?.length > 0 && this.setCarouselState()
                }
                _handleEnter(){
                    this.setLogoutPopupState()
                }
            },
            class VerticalList extends this{
                $enter(){
                    this.tag("ImgPreview").alpha = 0.0001
                    this.tag("LogoutPopup").alpha = 0.0001
                }
                _getFocused(){
                    return this.tag("VerticalList")
                }
                _handleUp(){
                    this.setTopStripState()
                }
            },
            class ImgPreview extends this{
                $enter(){
                    this.tag("ImgPreview").alpha = 1
                }
                $exit(){
                    this.tag("ImgPreview").alpha = 0.0001
                }
                _getFocused(){
                    return this.tag("ImgPreview")
                }
                _handleRight(){
                    if(this.cardIndex < this.imgPreviewData.length-1){
                        this.cardIndex = this.cardIndex + 1
                        const currentData = this.imgPreviewData[this.cardIndex]
                        this.changeImgPreviewImg(currentData,this.imgPreviewData.length,this.cardIndex,"right")
                    }
                }
                _handleLeft(){
                    if(this.cardIndex > 0){
                        this.cardIndex = this.cardIndex - 1
                        const currentData = this.imgPreviewData[this.cardIndex]
                        this.changeImgPreviewImg(currentData,this.imgPreviewData.length,this.cardIndex,"left")
                    }
                }
                _handleEnter(){
                    return
                }
                _handleBack(){
                    this.setCarouselState()
                }
            },
            class LogoutPopup extends this{
                $enter(){
                    this.tag("LogoutPopup").alpha = 1
                    this.tag("ImgPreview").alpha = 0.0001
                }
                $exit(){
                    this.tag("LogoutPopup").alpha = 0.0001
                }
                _getFocused(){
                    return this.tag("LogoutPopup")
                }
            },
            class Screensaver extends this{
                $enter(){

                }
                $exit(){

                }
                _getFocused(){
                    return
                }
            }
        ]
    }

}