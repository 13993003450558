import { Lightning, Colors, Utils } from "@lightningjs/sdk";
import { animation } from "../lib/helpers.js";
import Swirl from '../shaders/Swirl.js';
import ActivationBox from "./ActivationBox.js";

export default class AmbientBackground extends Lightning.Component {
    static _template() {
        return {
            w: 1920,
            h: 1080,
            rect: true,
            LightTheme:{
                alpha:0.0001,
                w: 1920, h: 1080,
                src: Utils.asset('images/lightBg.png'),
                shader: {type: Swirl, blur: 0.008, pull: 8},
                Logo:{
                    x:704,
                    y:520,
                    src: Utils.asset("images/lightThemeLogo.png")
                }
            },
            DarkTheme:{
                alpha:1,
                w: 1920, h: 1080,
                src: Utils.asset('images/darkBg.png'),
                shader: {type: Swirl, blur: 0.008, pull: 8},
                Logo:{
                    x:704,
                    y:520,
                    src: Utils.asset("images/darkThemeLogo.png")
                }
            },
        }
    }

    _init(){
        this.theme = "dark"
    }


    // new one
    changeBackground(){
        const lightThemeAlpha = this.theme === "dark" ? 1 : 0.0001
        const darkThemeAlpha = this.theme === "dark" ? 0.0001 : 1

        this.tag("DarkTheme").setSmooth('alpha', darkThemeAlpha, {delay: 0.6, duration: 2});
        this.tag("LightTheme").setSmooth('alpha', lightThemeAlpha, {delay: 0.3, duration: 2});
        this.theme = this.theme === "dark" ? "light" : "dark"
    }

    slideLogoToLeft(){
        this.tag("LightTheme.Logo").setSmooth('x', 268, {delay: 0.8, duration: 2});
        this.tag("DarkTheme.Logo").setSmooth('x', 268, {delay: 0.8, duration: 2});
    }

    slideLogoToRight(){
        this.tag("LightTheme.Logo").setSmooth('x', 704, {delay: 0.8, duration: 2});
        this.tag("DarkTheme.Logo").setSmooth('x', 704, {delay: 0.8, duration: 2});
    }

    update() {
        this.changeBackground()
    }
}
