import { Lightning, Utils } from '@lightningjs/sdk'

export default class ImgPreview extends Lightning.Component {
    static _template(){
        return{
            zIndex:999,
            color:0x30141414,
            texture:lng.Tools.getShadowRect(1920, 1080, 0, 0, 0),
            ImgSrc:{
                w:1600,
                h:900,
                x:160,
                y:90.25
            },
            LeftBtn:{
                alpha:0,
                x:32,
                y:490,
                src:Utils.asset("images/leftBtn.png"),
            },
            RightBtn:{
                alpha:1,
                x:1788,
                y:490,
                src:Utils.asset("images/rightBtnFocused.png"),
                color:0xffffffff
            }
        }
    }

        
    setImgSrc(url,totalLength,currentIndex,pressedBtn="left"){
        this.tag("ImgSrc").setSmooth("src",Utils.proxyUrl(url))

        this.tag("LeftBtn").alpha = 1
        this.tag("RightBtn").alpha = 1

        if(currentIndex === 0){
            this.tag("LeftBtn").alpha = 0
            this.tag("LeftBtn").patch({
                src:Utils.asset("images/leftBtn.png"),
            })
            this.tag("RightBtn").patch({
                src:Utils.asset("images/rightBtnFocused.png"),
            })
        }
        if(currentIndex === totalLength-1){
            this.tag("RightBtn").alpha = 0
            this.tag("LeftBtn").patch({
                src:Utils.asset("images/leftBtnFocused.png"),
            })
            this.tag("RightBtn").patch({
                src:Utils.asset("images/rightBtn.png"),
            })
        }
        if(currentIndex > 0 && currentIndex < totalLength-1){
            this.tag("LeftBtn").alpha = 1
            this.tag("RightBtn").alpha = 1

            let leftBtnSource = pressedBtn === "left" ? "images/leftBtnFocused.png" : "images/leftBtn.png"
            let rightBtnSource = pressedBtn === "right" ? "images/rightBtnFocused.png" : "images/rightBtn.png"

            this.tag("LeftBtn").patch({
                src:Utils.asset(leftBtnSource),
            })
            this.tag("RightBtn").patch({
                src:Utils.asset(rightBtnSource),
            })
        }

    }

    _disable(){
        this.tag("ImgSrc").setSmooth("src",null)
    }


}