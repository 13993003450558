import { Lightning, Storage, Utils } from '@lightningjs/sdk'

export default class TopStrip extends Lightning.Component {
    static _template() {
        return {
            Shadow: {
                x: 0,
                y: 125,
                zIndex: 1,
                color: 0x10141414,
                texture: lng.Tools.getShadowRect(1920, 3, 0, 0, 0),
            },
            StripBox:{
                zIndex:10,
                w:1920-160,
                h:124,
                y:31,
                x:80,
                flex:{direction:"row"},
                MainLogo:{
                    src:Utils.asset("images/mainLogo.png"),
                    flexItem:{marginRight:970}
                },
                UserProfile:{
                    w:w=>w,
                    flex:{direction:"row",justifyContent:"space-between"},
                    Name:{
                        text:{
                            text:"Guest User",
                            fontWeight:500,
                            fontSize:25.92,
                            lineHeight:38.88,
                            textColor:0xff2E2E2E,
                            verticalAlign:"bottom",
                            wordWrap:false,
                            wordWrapWidth:345,
                            textOverflow:"ellipsis",
                            fontFace:"Medium"
                        }
                    },
                    Logout:{
                        zIndex:11,
                        w:179,
                        h:60,
                        texture:lng.Tools.getRoundRect(179, 60, 5, 1, 0xff37A0EA,true,0xffF3F6F9),
                        flex:{direction:"row",justifyContent:"center",alignItems:"center"},
                        BtnText:{
                            text:{
                                text:"Log Out",
                                fontWeight:500,
                                fontSize:22,
                                textColor:"0xff37A0EA",
                                lineHeight:27.72,
                                verticalAlign:"bottom",
                                fontFace:"Medium"
                            },
                            flexItem:{marginRight:8}
                        },
                        BtnLogo:{
                            src:Utils.asset("images/logout_blue.png"),
                        }
                    }
                }
            }
        }
    }

    _enable(){
        let value = Storage.get("userEmail")
        this.tag("UserProfile.Name").patch({
            text:{
                text:value || "Guest User",
            }
        })
    }

    _focus(){
        this.tag("Logout").patch({
            texture:lng.Tools.getRoundRect(179, 60, 5, 1, 0xff37A0EA,true,0xff37A0EA),
            BtnText:{
                text:{
                    textColor:"0xffffffff",
                }
            },
            BtnLogo:{
                src:Utils.asset("images/logout_normal.png"),
            }
        })
    }

    _unfocus(){
        this.tag("Logout").patch({
            texture:lng.Tools.getRoundRect(179, 60, 5, 1, 0xff37A0EA,true,0xffF3F6F9),
            BtnText:{
                text:{
                    textColor:"0xff37A0EA",
                }
            },
            BtnLogo:{
                src:Utils.asset("images/logout_blue.png"),
            }
        })
    }

    _active(){
    }
}