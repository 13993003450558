import { Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import ListBoxNormalStream from './ListBoxNormalStream'
import StreamLibrary from '../List/StreamLibrary'
import TitleLibrary from '../List/TitleLibrary'

export default class VerticalList extends Lightning.Component {
    static _template(){
        return{
            Carousel:{
                y:220,
                x:80,
                h:1080-220-30,
                w: 1920,
                type:List,
                direction:"column",
                spacing:50,
                clipping:true,
            }   
        }
    }

    _active(){
    }

    setCarouselData(dataObj){
        let {streamData,selected} = dataObj
        
        const selectedStreamLibrary = streamData.filter((obj)=>obj.stream_id === selected)

        let finalCarouselArray = []

        if(selectedStreamLibrary){
            finalCarouselArray = selectedStreamLibrary.map((obj)=>{
                return {
                    x:0,
                    y:0,
                    h:450+100,
                    type:StreamLibrary,
                    itemData:obj
                }
            })
        }
        const dataWithoutSelectedLibrary = streamData.filter((obj)=>obj.stream_id !== selected)

        let carouselArray

        if(dataWithoutSelectedLibrary.length === 1){
            carouselArray = dataWithoutSelectedLibrary.map((obj,ind)=>{
                return { 
                    x:0,
                    y:0,
                    h: 440,
                    type: TitleLibrary,
                    itemData:obj 
                }
            })
        }else{
            carouselArray = dataWithoutSelectedLibrary.map((obj,ind)=>{
                return { 
                    x:0,
                    y:0,
                    h: 400,
                    type: ListBoxNormalStream,
                    itemData:obj 
                }
            })
        }

        this.tag("Carousel").clear()
        this.tag("Carousel").items = [...finalCarouselArray,...carouselArray]

        this.tag('Carousel').scroll = {
            after: 1, //start scrolling after 3 items
            forward: 0.9, //unless last item: scroll forward if item bounds are after 90% of the List, or if value is above 1; scroll after f.e. 900 pixels
            backward: 0.1, //unless first item: scroll backward if item bounds are before 10% of the List, or if value is above 1; scroll before f.e. 50 pixels
        }
    }


    _getFocused(){
        return this.tag("Carousel")
    }

    

}
