import { Lightning, Utils } from '@lightningjs/sdk'

export default class StreamBtn extends Lightning.Component {
    static _template(){
        return{
            Logout:{
                zIndex:11,
                h:60,
                texture:lng.Tools.getRoundRect(this.btnW, 60, 5, 1, 0xff37A0EA,true,0xffffffff),
                flex:{direction:"row",justifyContent:"center",alignItems:"center"},
                BtnLogo:{
                    flexItem:{marginRight:8}
                },
                BtnText:{
                    text:{
                        text:"Log Out",
                        fontWeight:700,
                        fontSize:22,
                        textColor:"0xff37A0EA",
                        lineHeight:27.72,
                        verticalAlign:"middle",
                        fontFace:"Bold"
                    },
                },  
                
            }
        }
    }

    set label(data){
        this.btnLabel = data
        this.tag("BtnText").patch({
            text:{
                text:data
            }
        })
    }

    _init(){
        this.tag("Logout").patch({
            texture:lng.Tools.getRoundRect(this.btnW, 60, 5, 1, 0xff37A0EA,true,0xffffffff),
        })

        if(this.btnW === 256 && this.btnLabel === "Preview"){
            this.tag("Logout").patch({
                BtnLogo:{
                    src: Utils.asset("images/previewEye.png")
                }
            })
        }

    }


    set btnWidth(val){
        this.btnW = val
    }

    
    _focus(){
        this.tag("Logout").patch({
            texture:lng.Tools.getRoundRect(this.btnW, 60, 5, 1, 0xff37A0EA,true,0xff37A0EA),
            BtnText:{
                text:{
                    textColor:"0xffffffff",
                }
            }
        })

        if(this.btnW === 256 && this.btnLabel === "Preview"){
            this.tag("Logout").patch({
                BtnLogo:{
                    src: Utils.asset("images/previewEyeFocused.png")
                }
            })
        }
    }

    _unfocus(){
        this.tag("Logout").patch({
            texture:lng.Tools.getRoundRect(this.btnW, 60, 5, 1, 0xff37A0EA,true,0xffffffff),
            BtnText:{
                text:{
                    textColor:"0xff37A0EA",
                }
            }
        })

        if(this.btnW === 256 && this.btnLabel === "Preview"){
            this.tag("Logout").patch({
                BtnLogo:{
                    src: Utils.asset("images/previewEye.png")
                }
            })
        }
    }


}