import { Storage } from "@lightningjs/sdk"
import configurations from "../configurations/configurations"

export const getActivationCode = async()=>{
    const uniqueHouseId = configurations.householdId

    const isFreshAtv = Storage.get("isFreshAtv") || 0
    
    let url = configurations.baseUrl + configurations.urlEnv + `getatvcode?household_id=${uniqueHouseId}`

    // to solve atv issue
    if(isFreshAtv){
        url += `&fresh=1`
        Storage.set("isFreshAtv",0)
    }

    try{
        const response =  await fetch(url,{
            method:"GET",
            headers: {
                'Content-Type': 'application/json',
            },
        })
    
        return await response.json()
    }catch(err){
        console.log(err,"getActivationCode api failed")
    }


}