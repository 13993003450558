import { Lightning, VideoPlayer, Router, Storage } from '@lightningjs/sdk'
import { getScreenSaverInfo } from '../services/getScreenSaver.js'

import Hls from './Hls.js'

export default class Screensaver extends Lightning.Component {
  static _template() {
    return {
      alpha: 1,
      rect: true,
      color: 0xff091422,
      w: 1920,
      h: 1080,
    }
  }

  _active(){
    this.screensaverCalled()
  }


  async screensaverCalled(){
    this.fireAncestors('$hideBackground')
    const screenSaverResponse = await getScreenSaverInfo(Storage.get('household_id'))
    if (typeof screenSaverResponse === "object") {
      console.log("it is Object")
      this.screenSaverPlayBackUrl = screenSaverResponse.screen_saver_url
    } else if (typeof screenSaverResponse === "string") {
      console.log("It's a string.");
      this.screenSaverPlayBackUrl = 'https://cdn.theoplayer.com/video/big_buck_bunny/big_buck_bunny.m3u8'
    }
    window.Hls = Hls
    this._hls = new window.Hls({ liveDurationInfinity: true })
    VideoPlayer.consumer(this)
    this.videoEl = VideoPlayer._videoEl
    this.playing = true
    this.status = 'loaded'
    this.play()
      
  }


  _focus() {
    this.patch({ alpha: 1 })
  }

  _unfocus() {
    this.patch({ alpha: 0 })
  }

  play() {
    this.seeked = false
    if (window.Hls.isSupported()) {
      this._hls.loadSource(this.screenSaverPlayBackUrl)
      this._hls.attachMedia(this.videoEl)

      this.videoEl.onplay = () => {
      }

      this.videoEl.onloadstart = () => {
      }

      this.videoEl.onloadedmetadata = () => {
        VideoPlayer.show()
        VideoPlayer.play()
        setTimeout(() => {
          this.patch({ rect: false })
        }, 1000)
      }

      this.videoEl.ontimeupdate = () => {
        this.barTime = VideoPlayer.currentTime
      }

      this.videoEl.onended = () => {
        VideoPlayer.seek(0)
        VideoPlayer.play()
      }

      this.videoEl.onplaying = () => {
      }
    } else {
      this.patch({ rect: false })
      VideoPlayer.open('https://cdn.theoplayer.com/video/big_buck_bunny/big_buck_bunny.m3u8')
    }

    const videoDate = new Date()
    this.videoStartTime = videoDate
    
  }

  _handleBack() {
    this.playing = false
    VideoPlayer.close()
    this._hls.destroy()
    Router.back()
  }

  _handleKey() {
    this.playing = false
    VideoPlayer.close()
    this._hls.destroy()
    Router.back()
  }

  static get width() {
    return 1820
  }

  static get height() {
    return 7
  }

}

