import { Lightning, Router, Storage } from '@lightningjs/sdk'
import { ActivationBox } from '../components'
import { getActivationCode } from '../services/activationCode';
import { v4 as uuidv4 } from 'uuid';
import configurations from '../configurations/configurations';


export default class Login extends Lightning.Component {
    static _template() {
        return {
            ActivationBox:{
                type:ActivationBox
            },
        }
    }

    _getFocused(){
    }

    async _init(){
        this.getHouseholdId()
        // initial try to fetch activation code for once
        await this.fetchActivationCodes()
    }

    _active(){
        this.tag("ActivationBox").updatingActivationCodes("  ")
        setTimeout(async()=>{
            await this.fetchActivationCodes()
        },100)
        this.fireAncestors('$slideLogoToLeft');
        this.tag("ActivationBox").activationCodeDisplay()
        
        //check code every 10 sec
        this.codeInterval = setInterval(async()=>{
            await this.fetchActivationCodes()
        },10000)

    }

    async loadAfterLogout(){
        // this called when user got logged out
        const isFromSplashScreen = Storage.get("fromSplash")
        if(isFromSplashScreen){
            Storage.set("fromSplash",false)
            return
        }

        const isFromHomePage = Storage.get("isFromHomePage")
        if(isFromHomePage){
            //user is loggedout
            this.fireAncestors("$showBackground")
            this.fireAncestors("$ambientBgSignal") 
            Storage.remove("isFromHomePage")
        }
          
        
        this.getHouseholdId()
        // initial try to fetch activation code for once
        await this.fetchActivationCodes()

    }

    async fetchActivationCodes(){
        if(Storage.get("idToken")){
            return
        }//dont call api, if already logged In
        
        const result = await getActivationCode()
        this.code = result?.code || ""
        Storage.set("idToken",result?.idtoken)
        this.tag("ActivationBox").updatingActivationCodes(this.code)

        //storing emailId
        Storage.set("userEmail",result?.user_id)

        // when activated, navigate to main page
        if(result?.idtoken && result?.access_token){
            clearInterval(this.codeInterval)
            Router.navigate("main")
        }
    }

    getHouseholdId(){
        // setting householdId
        const householdId = Storage.get("household_id")
        if(householdId){
        }else{
            const newUuid = uuidv4()
            Storage.set("household_id",newUuid)
        }
        configurations.householdId = Storage.get("household_id")
    }

    _inactive(){
        clearInterval(this.codeInterval)
        this.fireAncestors("$slideLogoToRight")
        this.tag("ActivationBox").activationCodeHide()
    }

    _enable(){
    }

    _handleKey(){
    }

    _handleBack(){
        this.application.closeApp()
        window.close()
    }

}