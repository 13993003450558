import { Lightning, Router } from '@lightningjs/sdk'
import Carousel from '../mainpage/Carousel'
import StreamBtn from '../mainpage/StreamBtn'
import { List } from '@lightningjs/ui'

export default class StreamLibrary extends Lightning.Component {
    static _template(){
        return{
            CaroselHeader:{
                y:0,
                text:{
                    text:"My Library",
                    textColor:0xff141414,
                    fontWeight:500,
                    fontSize:32,
                    lineHeight:40.32,
                    fontFace:"Medium"
                }
            },
            Box:{       
                y:50,    
                texture:lng.Tools.getRoundRect(1760, 407.81, 8, 1, 0xffCCCCCC, true, 0xffFFFFFF),
                h:407.81+100,
                CurrentStreamBox:{
                    zIndex:500,
                    x:16,
                    y:16,
                    CurrentStreamText:{
                        h:45,
                        x:3,
                        text:{
                            text:"Current Screensaver",
                            fontWeight:500,
                            fontSize:28,
                            textColor:0xff141414,
                            verticalAlign:"center",
                            fontFace:"Medium"
                        }
                    },
                    Shadow:{
                        y:43,
                        color:0x60ECEAEB,
                        texture:lng.Tools.getShadowRect(1728, 3, 0, 0, 0),
                    }
                },
                FlexBox:{
                    y:61+10,
                    x:19,
                    flex:{direction:"column"},
                    CarouselName:{
                        text:{
                            text:"Cityscape",
                            fontWeight:400,
                            fontSize:24,
                            textColor:0xff141414,
                            fontFace:"Medium"
                        }
                    },
                    TotalPhotos:{
                        text:{
                            text:"",
                            fontWeight:400,
                            fontSize:22,
                            textColor:0xff999999,
                            fontFace:"Medium"
                        }
                    },
                },

                CardList:{
                    x: 4,
                    y:166,
                    type:Carousel,
                },

                BtnList:{
                    x:1450,
                    y:430,
                    type:List,
                    itemType:StreamBtn,
                    items:[{x:0,w:256,label:"Preview",btnWidth:256}]
                }
            } 
            


        }
    }

    set itemData(value){
        this.streamData = value
        this.tag("CarouselName").patch({
            text:{
                text:value.stream_name
            }
        })
        this.tag("TotalPhotos").patch({
            text:{
                text: `${value?.images?.length || 0} images`
            }
        })
        
        this.tag("CardList").carouselData = value
    }

    _active(){
        this.setToCarouselState()
    }

    
    setToCarouselState(){
        this._setState('CardList');
    }

    setToBtnListState(){
        this._setState('BtnList');
    }


    static _states() {
        return[
            class CardList extends this{
                $enter(){
                    
                }
                $exit(){
                    
                }
                _getFocused(){
                    return this.tag("Box.CardList")
                }
                _handleDown(){
                    this.setToBtnListState()
                }
            },
            class BtnList extends this{
                $enter(){
                    
                }
                $exit(){
                    
                }
                _getFocused(){
                    return this.tag("Box.BtnList")
                }
                _handleUp(){
                    this.setToCarouselState()
                }
                _handleEnter(){
                    Router.getActivePage().startPreviewShow(this.streamData?.stream_id || "")
                }
            }
        ]
    }

}