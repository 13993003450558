import { Lightning, Router, Storage } from '@lightningjs/sdk'

export default class Splash extends Lightning.Component {
    static _template() {
        return {
        }
    }

    _init(){
        
    }

    _enable(){
        Storage.set("fromSplash",true)
    }
}