import { Router, Storage } from "@lightningjs/sdk"
import { Login, Main, Screensaver, Splash } from "../pages"
import SlideShow from "../pages/SlideShow"


const routes = [
    {
        path: 'login',
        component: Login,
        on: async (page) => {
            page.loadAfterLogout()
        },
        widgets: []
    },
    {
        path: 'main',
        component: Main,
        on: async (page) => {
            page.widgets.loader.alpha = 1
            await page.getStreamData()
            Router.focusPage()
        },
        widgets: ['loader']
    },
    {
        path: 'screensaver',
        component: Screensaver
    },
    {
        path: 'slideshow',
        component: SlideShow,
        widgets: ['loader']
    },
    {
        path: '$',
        component: Splash
    },
]

export default {
    root:()=>{
        return new Promise((resolve) => {
            if(Storage.get("idToken")){
                resolve(routes[1].path) //main page
            }else{
                resolve(routes[0].path) //login page
            }
          })
    },
    boot:()=>{
        Storage.set("isFreshAtv",1)
        Router.resume()
    },
    routes
}