import { Lightning } from '@lightningjs/sdk'

export default class ActivationBox extends Lightning.Component {
    static _template() {
        return {
            alpha:0.0001,
            zIndex:10,
            x: 940.5,
            y: 328.83,
            WrapperBox:{
                zIndex:11,
                w: 873,
                h: 524.33,
                rect: true,
                color:0x99010101,
                shader: {type: Lightning.shaders.RoundedRectangle, radius: 16, stroke: 3, strokeColor:0x61ffffff},
            },
            ContentBox:{
                zIndex:15,
                x: 80,
                y: 32,
                w: 713,
                h: 358.33,
                flex:{direction:"column",},
                Header:{
                    w: 713,
                    text:{
                        text:"Activate TV",
                        fontSize:40,
                        fontWeight:600,
                        textAlign:"center",
                        fontFace:"Medium"
                    },
                    flexItem:{marginBottom:32}
                },
                Step1:{
                    x:16,
                    w: 713,
                    text:{
                        text:"1. Go to https://picflow.tv2zprod.com from a web browser.",
                        fontSize:28,
                        fontWeight:400,
                        fontFace:"Medium"
                    },
                    flexItem:{marginBottom:24}
                },
                Step2:{
                    x:16,
                    w: 700,
                    text:{
                        text:`2. Login/Register into the management portal.`,
                        fontSize:28,
                        fontWeight:400,
                        fontFace:"Medium"
                    },
                    flexItem:{marginBottom:32}
                },
                Step3:{
                    x:16,
                    w: 700,
                    text:{
                        text:`3. Go to Settings -> Activate TV in the brower and enter the following code:`,
                        fontSize:28,
                        fontWeight:400,
                        fontFace:"Medium"
                    },
                    flexItem:{marginBottom:32}
                },
                Codes:{
                    w: 713,
                    text:{
                        fontSize:60,
                        fontWeight:600,
                        textAlign:"center",
                        lineHeight:84,
                        fontFace:"Medium"
                    },
                }
            }
            
        }
    }
    
    _init(){
        this.hideActivationCodesAnimation = this.tag("ContentBox.Codes").animation({delay: 0.2, duration: 1, actions: [
            {p: 'alpha', v: {
                0   : 1,
                0.2 : 0.8,
                0.6 : 0.4,
                1   : 0.001
            }},
        ]});
    }

    activationCodeDisplay(){
        this.setSmooth('alpha', 1, {delay: 2, duration: 2});
    }


    activationCodeHide(){
        this.setSmooth('alpha', 0, {delay: 2, duration: 2});
    }


    updatingActivationCodes(newVal){
        newVal = newVal.split("").join("  ")
        this.hideActivationCodesAnimation.start()
        this.updatingCodeAnimation = this.tag("ContentBox.Codes").animation({delay: 0.2, duration: 3, actions: [
            {p:'text.text', v:newVal},
            {p: 'alpha', v: {
                0   : 0.001,
                0.2 : 0.4,
                0.6 : 0.8,
                1   : 1
            }},
        ]});
        this.updatingCodeAnimation.start()
    }


    _disable(){
        this.tag("ContentBox.Codes").patch({
            text:{
                text:"",
                fontFace:"Medium"
            }
        })
    }



}   
