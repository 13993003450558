import { Lightning, Router, Utils } from '@lightningjs/sdk'
import { AmbientBackground, Backdrop } from './components';
import routerConfig from './lib/routes';
import { Loader } from './widgets';

export default class App extends Router.App {
  static getFonts() {
    return [
      { family: 'Bold', url: Utils.asset('fonts/Roboto-Bold.ttf') },
      { family: 'Medium', url: Utils.asset('fonts/Roboto-Medium.ttf') }
    ]
  }

  static _template() {
    return {
      Content: { 
        zIndex: 1, rtt: true, w: 1920, h: 1080,
        AmbientBackground: {type: AmbientBackground},
        Backdrop: {type: Backdrop},
        Pages: {
          forceZIndexContext: true
        }
      },
      Loading: {

      },
      Widgets: {
        Loader:{
          type:Loader
        }
      }
    }
  }

  _setup(){
    Router.startRouter(routerConfig, this);
  }

  _active(){
    // this timer helps in changing bg image every 5 seconds
    this.$ambientBgSignal()
  }

  $ambientBgSignal(){
    this.bgInterval = setInterval(()=>{
      this.$updateAmbientBackground()
    },3000)
  }

  $hideLogoBg(){
    clearInterval(this.bgInterval)
    this.tag('Content').patch({
      AmbientBackground: {smooth: {alpha: [0, {duration: 0.2, delay: 0.0}]}},
    });
  }

  _handleAppClose(){
    this.application.closeApp()
    window.close()
  }
  

  $hideBackground() {
    this.tag('Content').patch({
      AmbientBackground: {smooth: {alpha: [0, {duration: 0.2, delay: 0.0}]}},
      Backdrop: {smooth: {alpha: [0, {duration: 0.2, delay: 0.0}]}}
    });
  }

  $showBackground() {
    this.tag('Content').patch({
      AmbientBackground: {smooth: {alpha: [1, {duration: 0.2, delay: 0.2}]}},
      Backdrop: {smooth: {alpha: [1, {duration: 0.2, delay: 0.2}]}}
    });
  }

  $updateAmbientBackground() {
    this.tag('AmbientBackground').update();
  }

  $slideLogoToLeft(){
    this.tag('AmbientBackground').slideLogoToLeft();
  }

  $slideLogoToRight(){
    this.tag('AmbientBackground').slideLogoToRight();
  }
  
}
