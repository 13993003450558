import { Lightning, Utils } from '@lightningjs/sdk'

export default class Card extends Lightning.Component {
    static _template(){
        return{
            Img:{
                texture:lng.Tools.getRoundRect(320, 180.65,12 , 1, 0xffffffff),
                ImgView:{
                    w:320,
                    h:181,
                    src:Utils.asset("images/city.png"),
                    scale:1.0,
                }
            }
        }
    }

    set imgSrc(value){
        this.tag("Img.ImgView").patch({
            src: Utils.proxyUrl(value)
        })
    }

    _focus(){
        this.patch({
            Img:{
                scale:1.06,
                zIndex:50,
            }
        })
    }

    _unfocus(){
        this.patch({
            Img:{
                scale:1.0,
                zIndex:1,
            }
        })
    }


}