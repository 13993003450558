import { Lightning, VideoPlayer, Router, Storage } from '@lightningjs/sdk'
import Hls from './Hls.js'

export default class SlideShow extends Lightning.Component {
  static _template() {
    return {
      alpha: 1,
      rect: true,
      color: 0xff091422,
      w: 1920,
      h: 1080,
    }
  }


  set params(data){
    this.widgets.loader.alpha = 1
    this.playerInitialize()
    this.slideshowPlaybackUrl = data.playUrl
    if(this.slideshowPlaybackUrl && this.slideshowPlaybackUrl.includes(".m3u8")){
      this.play()
    }else{
      Router.back()
    }
  }
  
  async playerInitialize(){
    window.Hls = Hls
    this._hls = new window.Hls({ liveDurationInfinity: true })
    VideoPlayer.consumer(this)
    this.videoEl = VideoPlayer._videoEl
    this.playing = true
  }


  _focus() {
    this.patch({ alpha: 1 })
  }

  _unfocus() {
    this.patch({ alpha: 0 })
  }

  play() {
    this.seeked = false
    if (window.Hls.isSupported()) {
      this._hls.loadSource(this.slideshowPlaybackUrl)
      this._hls.attachMedia(this.videoEl)

      this.videoEl.onplay = () => {
      }

      this.videoEl.onloadstart = () => {
      }

      this.videoEl.onloadedmetadata = () => {
        VideoPlayer.show()
        VideoPlayer.play()
        setTimeout(() => {
          this.widgets.loader.alpha = 0
          this.patch({ rect: false })
        }, 1000)
      }

      this.videoEl.ontimeupdate = () => {
        if(this.widgets.loader.alpha === 1){
          this.widgets.loader.alpha = 0
        }
      }

      this.videoEl.onended = () => {
        //looping the video
        VideoPlayer.seek(0)
        VideoPlayer.play()
      }

      this.videoEl.onplaying = () => {
      }
    } else {
      this.patch({ rect: false })
      VideoPlayer.open('https://cdn.theoplayer.com/video/big_buck_bunny/big_buck_bunny.m3u8')
    }

  }

  _handleBack() {
    this.playing = false
    VideoPlayer.close()
    this._hls.destroy()
    Router.back()
  }


  static get width() {
    return 1820
  }

  static get height() {
    return 7
  }

}

