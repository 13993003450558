import { Lightning, Router, Utils } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import Card from './Card'

export default class Carousel extends Lightning.Component {
    static _template(){
        return{
            HorizontalList:{
                type:List,
                w:1755,
                h:255,
                clipping:true,
                direction:"row",
                itemType:Card,
                scrollTransition: { duration: 0.4 },
            }
        }
    }

    set carouselData(data){
        this.streamData = data
        this.tag("HorizontalList").items = data.images.map((item)=>{
            return {
                w:336,
                h:250,
                y:10,
                x:20,
                itemType:Card,
                imgSrc:item
            }
        }) 
        this.tag('HorizontalList').scroll = {
            after: 3, //start scrolling after 3 items
            forward: 0.9, //unless last item: scroll forward if item bounds are after 90% of the List, or if value is above 1; scroll after f.e. 900 pixels
            backward: 0.1, //unless first item: scroll backward if item bounds are before 10% of the List, or if value is above 1; scroll before f.e. 50 pixels
        }
    }

    get slider(){
        return this.tag("HorizontalList")
    }

    _active(){

    }

    _getFocused(){
        return this.tag("HorizontalList")
    }

    _handleEnter(){
        const currentItem = this.streamData?.images
        Router.getActivePage().AddSrcToImgPreview(currentItem,this.slider._index)
    }



}