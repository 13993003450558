import { Lightning, Router, Utils } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import LogoutBtn from './LogoutBtn'

export default class LogoutPopup extends Lightning.Component {
    static _template(){
        return{
            zIndex:120,
            color:0x60141414,
            texture:lng.Tools.getShadowRect(1920, 1080, 0, 0, 0),
            Popup:{
                texture:lng.Tools.getRoundRect(680, 346, 8, 1, 0xffffffff,true,0xffffffff),
                x:620,
                y:367,
                FlexBox:{
                    zIndex:125,
                    y:48,
                    flex:{direction:"column",alignItems:"center"},
                    WarningIcon:{
                        src:Utils.asset("images/warning.png"),
                        flexItem:{marginBottom:24}
                    },
                    Header:{
                        text:{
                            text:"Log Out",
                            fontWeight:400,
                            fontSize:28,
                            textColor:0xff333333,
                            fontFace:"Medium"
                        },
                        flexItem:{marginBottom:16}
                    },
                    SubHeader:{
                        w:680,
                        text:{
                            text:"Are you sure you want to log out of your account?",
                            fontWeight:400,
                            fontSize:24,
                            textColor:0xff333333,
                            textAlign:"center",
                            fontFace:"Medium"
                        },
                        flexItem:{marginBottom:32}
                    },
                    BtnList:{
                        w:520,
                        x:80,
                        type:List,
                        itemType:LogoutBtn,
                        items:[{x:0,w:180,label:"Go Back"},{x:16,w:175,label:"Log Out"}]
                    }
                }
            }
        }
    }

    
    _getFocused(){
        return this.tag("BtnList")
    }

    _handleEnter(){
        const selectedIndex = this.tag("BtnList")._index
        switch(selectedIndex){
            case 0:{
                // logout btn in top-strip should be focused 
                Router.getActivePage().setTopStripState()
                break;
            }
            case 1:{
                // logout here
                Router.getActivePage().logoutUser()
                break;
            }
            default:{
                break;
            }
        }
    }


}